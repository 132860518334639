'use strict';

/** InputValidation constructor
 *  ---------------------------
 *  @description
 *  Object to create small input element wrapper for form validation,
 *  it will add event listener to add/remove cssClass regarding element status
 *  add error message content and toggle it regarding input validity
 state change
 *
 *  @example

 var email = new InputValidation(document.querySelector('input[name="email"]', {
        dirty: 'dirty',
        touched: 'touched',
        valid: 'has-success',
        invalid: 'has-error'
      });

 *  @param element {HTMLElement} - a Dom instace of an `input` element
 *  @param stateClass {object} -  mapping object for css class to apply on element parent when object
 *          - dirty, touched, valid, invalid
 */
export const InputValidation = function (element, stateClass) {
  /** The input itself
   * @type HTMLElement
   * */
  this.el = element;

  /** Map of css class to apply (value) regarding specific component state (key)
   * @type object
   * */
  this.stateClass = stateClass ||
    {
      dirty: 'dirty',
      touched: 'touched',
      valid: 'has-success',
      invalid: 'has-error',
    };

  /** Whenever the field has been touched or not
   *  - touched meand blur event have been fired once on form
   * @type boolean
   * */
  this.touched = false;

  /** Whenever the field is valid or not
   * @type boolean
   * */
  this.valid = false;

  /** Element where we should put and display error-message
   * @type HTMLElement
   * */
  this.errorEl = element.parentElement.querySelector('[data-error]');

  this.addTouchedListener();

};

/** Update object property `touched` and add/remove stateClass.touched on parent element,  regarding value given as params
 * If touched will also run `validation` methods and listeners (this.setValid && this.addValidListener)
 * @param isTouched {boolean} Whenever you'd like to set this instance as touched (true) or not (false)
 * @return void
 * */
InputValidation.prototype.setTouched = function (isTouched) {
  this.touched = isTouched;
  this.el.parentElement.classList[isTouched ? 'add' : 'remove'](this.stateClass.touched);
  if (isTouched) {
    this.addValidListener();
    this.setValid(this.el.validity.valid);

  }
};

/** Update object property 'valid' and add/remove stateClass.valid/invalid on parent element, regarding value given as params.
 * It will also run `this.updateError` method.
 * @params isValid {boolean} Whenerver you'dlike to set this instance as valid (true) or not (falseà
 *  @return void
 * */
InputValidation.prototype.setValid = function (isValid) {
  this.valid = isValid;
  this.el.parentElement.classList[isValid ? 'remove' : 'add'](this.stateClass.invalid);
  this.el.parentElement.classList[isValid ? 'add' : 'remove'](this.stateClass.valid);
  if (this.errorEl) {
    this.updateError();

  }
};

/** Add a listener who will launch `this.setTouched` first time `this.el`'s  `blur event` is launched.
 * @return void
 * */
InputValidation.prototype.addTouchedListener = function () {
  var self = this;
  this.el.addEventListener('blur', function listener() {
    self.setTouched(true);
    self.el.removeEventListener('blur', listener);

  });
};

/** Add a listener whow will `this.doValidation` when input value change.
 * @return void
 * */
InputValidation.prototype.addValidListener = function () {
  if (this.stateListener) {
    return;

  }
  var self = this;
  this.stateEvent = this.el.type.toLowerCase() !== 'radio' ? 'input' : 'change';
  this.stateListener = this.el.addEventListener(this.stateEvent, function () {
    self.doValidation();

  });
};

/** Update html error message using `this.el` "data-error" attribute value.
 *  @return void
 * */
InputValidation.prototype.updateError = function () {
  if (!this.valid) {
    this.errorEl.classList.add('active');
    this.errorEl.innerHTML = this.errorEl.dataset['error'];

  } else {
    this.errorEl.classList.remove('active');
    this.errorEl.innerHTML = '';
  }
};


/** Launch validation on this object
 *  Recover this.el.validity to define instance validity
 *  @return void
 * */
InputValidation.prototype.doValidation = function () {
  this.setValid(this.el.validity.valid);

};
