/**
 *  Add parameter 'cookieAccept' to document.cookie
 *
 *  If expire is not provided or equal to 0 the cookie will be deleted at the end of session
 *  elsewhere it indicates the number of days from now.
 */
export function setCookie(name: string, value: string, expire: number): void {
  const date = new Date();
  let expires = '';
  if (expire !== undefined) {
    date.setTime(date.getTime() + (expire * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + '=' + value + expires;
}
