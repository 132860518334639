'use strict';

export const viewSwitch = {
  /**
   * Reference to the first item HTMLElement
   * @type HTMLElement
   */
  firstItem: {},
  /**
   * Reference to the second item HTMLElement
   * @type HTMLElement
   */
  secItem: {},
  /**
   * Reference to the wrapper HTMLElement
   * @type HTMLElement
   */
  inner: {},


  /**
   * Init properties according to value given in options param
   * @param options {object}
   */
  init: function (options) {
    this.firstItem = options.firstItem;
    this.secItem = options.secItem;
    this.inner = this.firstItem.parentElement;

    this.inner.style.minHeight = this.inner.offsetHeight + 'px';
  },

  /**
   * Helper to recover an element position, used to scrollTo an element when view change
   * @param elem
   * @return {number}
   */
  getElementScrollPosition: function (elem) {
    var offsetTop = 0;
    do {
      if (!isNaN(elem.offsetTop)) {
        offsetTop += elem.offsetTop;
        elem = elem.parentElement;
      }
    } while (elem.nodeName.toLowerCase() !== 'body');
    return offsetTop;
  },

  /**
   * Reference to know if we swtich from 1 to 2 or 2 to 1
   * @type boolean
   */
  switchBack: false,

  /**
   * Reference to know if anim is running
   * @type boolean
   */
  isRunning: false,

  /**
   * Method will recover which item is active (by checking class), and switch them using css transition class
   */
  doSwitch: function () {

    // unsure we don't run this whil it's performing
    if (this.isRunning) {
      return false;
    }
    this.isRunning = true;

    // prepare references for enter/leave items
    var self = this;
    var itemLeave = !this.switchBack
      ? this.firstItem
      : this.secItem;
    var itemEnter = this.switchBack
      ? this.firstItem
      : this.secItem;

    this.switchBack = !this.switchBack;

    // set a min-height to inner
    this.inner.style.minHeight = this.inner.offsetHeight + 'px';

    // prepare item to class switch
    itemLeave.classList.add('switch-leave-prepare');
    itemEnter.classList.add('switch-enter-prepare');
    itemEnter.classList.remove('switch-leave-end');
    itemEnter.classList.remove('switch-leave-prepare');

    // performed when leave item transition end
    function leaveEnd(e) {
      if (e.target !== itemLeave) {
        return;
      }

      // update animation classes
      itemLeave.classList.add('switch-leave-end');
      itemLeave.classList.remove('switch-leave');
      // remove listener
      itemLeave.removeEventListener('transitionend', leaveEnd);

    }

    // performed when enter item transition end
    function enterEnd(e) {
      if (e.target !== itemEnter) {
        return;
      }
      // update animation classes
      itemEnter.classList.remove('switch-enter-prepare');
      itemEnter.classList.remove('switch-enter');

      // set this has not running anymore
      self.isRunning = false;

      // remove listener
      itemEnter.removeEventListener('transitionend', enterEnd);

    }

    // Perform class addition on a timetout, so we are sure elements are ready
    // I'd like to find a better solution for this
    setTimeout(function () {
      // add listener for `transitionend`
      itemLeave.addEventListener('transitionend', leaveEnd);
      itemEnter.addEventListener('transitionend', enterEnd);

      // update inner min-height to fit enter item height
      self.inner.style.minHeight = (itemEnter.offsetHeight) + 'px';

      // add class to perform animation
      itemLeave.classList.add('switch-leave');
      itemEnter.classList.add('switch-enter');

      // scroll to item to ensure all content is visible
      //window.scrollTo(0,self.getElementScrollPosition(itemEnter) - 50);

    }, 500);
  },
};
