import { FormValidation } from './FormValidation';
import { FormFeedback } from './FormFeedback';

'use strict';

/** Form submit constructor
 * -----------------------
 * @requires FormFeedback
 * @requires FormValidation
 * @description
 * And extend of FormValidation model to perform ajax submit for a valid form.
 * It will also manage feedBack
 * @param {Element} form - the form element on which we will perform ajax submit
 * @param {Object} options - options for constructor
 * @extends FormValidation
 * @return void
 * */
export const FormSubmit = function (form, options?) {
  // apply parent FormValidation constructor to this instance
  FormValidation.call(this, form, options);

  /** A formFeedback instance to communicate form states
   * @type FormFeedback
   * */
  this.btnSubmit = undefined;

  /** The Ajax request
   * @type XMLHttpRequest
   * */
  this.request = undefined;

  /** Whenever or not form submit is in progress
   * @type boolean
   * */
  this.isPending = false;

  /**
   *  Store of method to call when form as been send
   *  @type Array[function]
   * */
  this.onSuccessQueue = options.onSuccessQueue || [];

  // initialize the form feedBack instance
  this.setFormFeedback();
  // init form submit listener
  this.addSubmitListener();

};

// Extend this model with FormValidation prototypes
FormSubmit.prototype = Object.create(FormValidation.prototype);

/** Create the FormFeedback instance and store it into this.btnSubmit
 * @return void
 * */
FormSubmit.prototype.setFormFeedback = function () {
  var btn = this.el.querySelector('button[type="submit"]');
  this.btnSubmit = new FormFeedback(btn);

};

/** Extend parent method to inform this.btnSubmit formFeedback instance about status change
 * @return void
 * */
FormSubmit.prototype.setSubmitted = function () {
  FormValidation.prototype.setSubmitted.call(this);
  this.btnSubmit.setStatus('pending');
};

/** Add the for submit listener and store a reference into this.submitListener
 * @return void
 * */
FormSubmit.prototype.addSubmitListener = function () {
  var self = this;
  this.submitListener = this.el.addEventListener('submit', function (e) {
    e.preventDefault();
    if (!self.isValid()) {
      return false;
    }
    self.submit();

  });
};

/** Perform request submit and store it in this.request
 * and configure the onreadystatechange method to this.onreadystatechange
 * @return void
 * */
FormSubmit.prototype.submit = function () {
  // if form is pending cancel this new request
  if (this.isPending) {
    return;
  }

  let self = this;

  this.request = new XMLHttpRequest();
  this.request.onreadystatechange = function () {
    self.onreadystatechange(this);
  };
  this.request.open('POST', 'https://kryus-mail.dcode.eu/flask/send');
  this.request.setRequestHeader('Content-Type', 'application/json');
  this.request.send(this.getFormData(this.el));
  this.setSubmitted(true);
};

/** Will be assigned to this.request.onreadystatechange method
 * Call related methods for a given status 'pending', 'success', 'error'
 * @param {XMLHttpRequest} request - the request who launched this event
 * */
FormSubmit.prototype.onreadystatechange = function (request) {
  if (request.readyState === XMLHttpRequest.LOADING) {
    this.isPending = true;
    this.onPending();
  }

  if (request.readyState === XMLHttpRequest.DONE) {
    this.isPending = false;
    var response = JSON.parse(request.responseText);
    if (response.success) {
      this.onSuccess();
    } else {
      this.onError(response.success);
    }
  }
};

/** Set of instructions to apply when request gain success status
 * - clear this.errorMessage
 * - set this.btnSubmit status to success
 * - remove the "disabled" attribute from button
 * @return void
 * */
FormSubmit.prototype.onSuccess = function () {
  this.btnSubmit.errorMessages = [];
  this.btnSubmit.el.removeAttribute('disabled');
  this.resetFormData();

  if (this.onSuccessQueue.length) {
    this.onSuccessQueue.forEach(function (fn) {
      fn();
    });
  } else {
    this.btnSubmit.setStatus('success');

  }
};

/** Set of instructions to apply when request gain error status
 * - recover error messages from response
 * - set this.btnSubmit status to error
 * - remove the "disabled" attribute from button
 * @param {object} response - the JSON.Parsed response from server
 * @return void
 * */
FormSubmit.prototype.onError = function (response) {
  this.btnSubmit.errorMessages = response.error ? response.error[0].list : [];
  this.btnSubmit.setStatus('error');
  this.btnSubmit.el.removeAttribute('disabled');
};

/** Set of instructions to apply when request gain pending status
 * - clear this.errorMessage
 * - set this.btnSubmit status to pending
 * - add the "disabled" attribute from button
 * @return void
 * */
FormSubmit.prototype.onPending = function () {
  this.btnSubmit.errorMessages = [];
  this.btnSubmit.setStatus('pending');
  this.btnSubmit.el.setAttribute('disabled', 'true');

};

/** Loop over each input item and launch resteData method
 *  @return void
 * */
FormSubmit.prototype.resetFormData = function () {
  this.el.reset();
  this.inputs.forEach(function (item) {
    item.setTouched(false);
  });
};

/** Get form data and return them to JSON format
 *  @return {object}
 * */
FormSubmit.prototype.getFormData = function (form) {
  let formData: FormData = new FormData(form);
  let objectData = {};

  formData.forEach((value, key) => {
    objectData[key] = value;
  });

  return JSON.stringify(objectData);
};
