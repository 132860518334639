/**
 * Check userAgent to detext IOS device
 * @return {boolean}
 */
function getIsIos() {
  const ua = window.navigator.userAgent;
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  const webkit = !!ua.match(/WebKit/i);
  return iOS && webkit;
}

/**
 * Body tag reference
 * @type {*|Object}
 */
const body: HTMLElement = document.body;
/**
 * Html tag reference
 * @type {*|Object}
 */
const html: HTMLElement = document.querySelector('html');
/**
 * Know if we are on IOS device, if so we'll have to extend rules to block scrolling
 * when modal is open
 * @type boolean
 */
const isIOS = getIsIos();

export function disableScroll(block: boolean) {
  if (block) {
    body.classList.add('disable-scroll');
    html.classList.add('disable-scroll');
    if (isIOS) {
      body.classList.add('disable-scroll--ios');
      html.classList.add('disable-scroll--ios');
    }
  } else {
    body.classList.remove('disable-scroll');
    html.classList.remove('disable-scroll');
    if (isIOS) {
      body.classList.remove('disable-scroll--ios');
      html.classList.remove('disable-scroll--ios');
    }
  }
}
