export function toggleBullet(element) {
  const sectionId = element.getAttribute('id');
  const bulletId = document.querySelector(`li[id="${sectionId}"]`);
  const sectionSlideInUp = document.getElementById('section-facts-slideInUp');
  const sectionBigDataAnim = document.getElementById('section-bigData-animation');

  function toggleBulletIndicator() {
    const elY = element.getBoundingClientRect().top;
    const elBottom = element.getBoundingClientRect().bottom;
    const windowMidHeight = window.innerHeight / 2;

    if (elY < windowMidHeight && elBottom > windowMidHeight) {
      bulletId.parentElement.querySelectorAll('.active').forEach(item => {
        item.classList.remove('active');
      });
      bulletId.classList.add('active');
      if (element.classList.contains('section--facts')) {
        sectionSlideInUp.classList.add('slideInUp');
      }
      if (element.classList.contains('section--big-data')) {
        sectionBigDataAnim.classList.add('slideInUp');
      }
    }
  }

  window.addEventListener('scroll', () => {
    toggleBulletIndicator();
  });
}
