import { setCookie } from './set-cookie';

// tslint:disable-next-line:class-name
export class cookieBanner {
  cookieBanner: HTMLElement;
  cookieBannerCTA: HTMLElement;
  eventListener: EventListenerOrEventListenerObject;

  // tslint:disable-next-line:no-shadowed-variable
  constructor(cookieBanner: string, cookieBannerCTA: string) {
    this.cookieBanner = document.querySelector(cookieBanner);
    this.cookieBannerCTA = this.cookieBanner.querySelector(cookieBannerCTA);
    this.eventListener = () => {
      this.acceptCookie();
    };
  }

  private acceptCookie() {
    setCookie('cookieAccept', 'true', 365);
    this.close();
  }

  show() {
    this.cookieBanner.classList.add('visible');
    this.cookieBannerCTA.addEventListener('click', this.eventListener);
  }

  close() {
    this.cookieBanner.classList.remove('visible');
    this.cookieBannerCTA.removeEventListener('click', this.eventListener);
  }
}
