import { InputValidation } from './InputValidation';

'use strict';

/** FormValidation constructor
 *  -------------------------
 *  @requires InputValidation
 *  @description
 *  Add eventListeners on form submit to perform validation and prevent submit if form not valid.
 *  Perform inputs validation but creating an `InputValidation` instance for each form's inputs or textarea.
 *  @param form {Element} - the form on which perform validation
 *  @param options {object} - list of option for validation
 *      - stateClass: mapping of css class to apply regarding state, it cover form and inputs state passing this object to each InputValidation instance
 *      - inputs: optional pre-selection of inputs inside the form, if not given will perform validation on any input,text-area
 *  @return void
 * */
export const FormValidation = function (form, options?) {
  options = options ? options : {};

  /** The form itself
   * @type Element
   * */
  this.el = form;

  /** Array of form inputs
   * @type Array[Element]
   * */
  this.inputs = [];

  /** Whenever the form is valid or not
   * @type boolean
   * */
  this.valid = false;

  /** Whenever the form is submitted or not
   * @type boolean
   * */
  this.submitted = false;

  /** Map of css class to apply (value) regarding specific component state (key)
   * @type object
   * */
  this.stateClass = options.stateClass ?
    options.stateClass :
    {
      dirty: 'dirty',
      touched: 'touched',
      valid: 'has-success',
      invalid: 'has-error',
      submitted: 'submitted',
    };

  // init InputValidation instance creation
  this.initInputs(options.inputs ? options.inputs : form.querySelectorAll('input, textarea'));

};

/** Create an InputValidation instance for each form element given as params
 *  @params formElement {Array[ELEMENT_Node]} - form element for wich we should create an InputValitaion instance
 *  @return void
 * */
FormValidation.prototype.initInputs = function (formElement) {
  var self = this;
  this.inputs = [];
  formElement.forEach(function (element) {
    self.inputs.push(new InputValidation(element, self.stateClass));

  });
};

/** Update this.submitted value and toggle this.stateClass.submitted on form.
 *  @param isSubmitted {boolean}
 *  @return void
 * */
FormValidation.prototype.setSubmitted = function (isSubmitted) {
  this.submitted = isSubmitted;
  this.el.classList[isSubmitted ? 'add' : 'remove'](this.stateClass.submitted);

};

/** Loop trough each `this.inputs` and launch validation method for InputValidation instance.
 *  If one input is not valid, this.valid is set to false.
 *  @return void
 * */
FormValidation.prototype.doValidation = function () {
  var self = this;
  self.valid = true;
  this.inputs.forEach(function (item) {
    item.doValidation();
    item.addValidListener();
    if (!item.valid) {
      return self.valid = false;

    }
  });
};

/** Perform validation methos and return this.valid value
 *  @return {boolean} whenever isValid (true) or not (false)
 * */
FormValidation.prototype.isValid = function () {
  this.doValidation();
  return this.valid;

};

/** Add submit event listener on form, so it perform validation and prevent submit if form not valid.
 *  @return void
 * */
FormValidation.prototype.addSubmitListener = function () {
  if (this.submitListener) {
    return;

  }
  var self = this;
  this.submitListener = this.el.addEventListener('submit', function (e) {
    if (!self.isValid()) {
      e.preventDefault();

    }
    self.setSubmitted(true);

  });
};
