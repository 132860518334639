import { disableScroll } from './public_api';

export function addBurgerMenuHandler() {
  const burgerMenuButton = document.querySelector('.burger-menu');
  const menuContainer = document.querySelector('.header-menu');
  let opened = false;

  function toggleMenu() {
    opened = !opened;
    if (opened) {
      menuContainer.classList.add('header-menu--open');
      burgerMenuButton.classList.add('cross');
      burgerMenuButton.classList.remove('uncross');
    } else {
      menuContainer.classList.remove('header-menu--open');
      burgerMenuButton.classList.add('uncross');
      burgerMenuButton.classList.remove('cross');
    }
  }

  burgerMenuButton.addEventListener('click', (e) => {
    toggleMenu();
  });
}
