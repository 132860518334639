const $ = require('jquery');
import 'bootstrap/js/src/collapse';
import 'bootstrap/js/src/dropdown';
import 'bootstrap/js/src/carousel';

import {
  addBurgerMenuHandler,
  FormSubmit,
  viewSwitch,
  getDate,
  getCookie,
  cookieBanner,
  setActiveClass,
  toggleBullet,
} from './methods/public_api';

window.addEventListener('DOMContentLoaded', () => {
  setActiveClass();
  if (!getCookie('cookieAccept')) {
    new cookieBanner('.cookie-banner-wrapper', '.cookie-banner__cta').show();
  }
  getDate();

  $('.carousel').carousel({
    interval: false,
  });
  addBurgerMenuHandler();

  const form = document.querySelector('#contact-form');

  /* View feedback switch
	* ------------------- */
  // init view switch
  if (form) {
    viewSwitch.init(
      {
        firstItem: document.querySelector('#switch-view-form'),
        secItem: document.querySelector('#switch-view-feedback'),
      },
    );

    /** Form validation instance
     * @type FormSubmit
     * */
    // tslint:disable-next-line:no-unused-expression
    new FormSubmit(form,
      {
        onSuccessQueue: [function () {
          // Success message display
          viewSwitch.doSwitch.call(viewSwitch);
        }],
      });
  }
  /**
   * Capture a list with the data-attribute 'data-scroll-spy'
   */
  const spies: NodeList = document.querySelectorAll('[data-scroll-spy]');
  if (spies.length > 0) {
    spies.forEach(function (spy) {
      toggleBullet(spy);
    });
  }
});
