export function setActiveClass() {

  const elemToActive = document.querySelectorAll('[data-active]');
  const menuDropdown = document.querySelector('.dropdown-toggle');
  const dropdownItem = document.querySelectorAll('.dropdown-item');
  const homeActive = document.getElementById('homeActive');
  const classActive = 'active';

  elemToActive.forEach((element) => {
    const pathName = element.baseURI.split('/');
    const href = element.getAttribute('href').split('/');
    setActive(element, href, pathName);
  });

  dropdownItem.forEach((el) => {
    if (el.classList.contains(classActive)) {
      menuDropdown.classList.add(classActive);
    }
  });

  function setActive(linkElement, href, pathName) {
    if (location.hostname === 'localhost') {
      if (href[1] === pathName[3]) {
        homeActive.classList.remove(classActive);
        linkElement.classList.toggle(classActive);
      }
    } else {
      // for Acc or Prod env
      if (href[0] === pathName[3]) {
        homeActive.classList.remove(classActive);
        linkElement.classList.toggle(classActive);
      }
    }
  }
}
