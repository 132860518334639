'use strict';

/** FormFeedBack constructor
 * ------------------------
 * @description
 * Switch feedBack status class onto a submit button.
 * Update button's content regarding feedBack status
 * Display additional error-message when feedBack status is 'error'
 * @param {Element} element - button submit of the form
 * @param {Object} options - set of option for constructor
 * @return void
 * */
export const FormFeedback = function (element, options?) {
  /** The button element, should be a submit button of a form
   * @type Element
   * */
  this.el = element;

  /** Will store last status to prevent further work if does not changed
   *  @type string
   */
  this.status = null;

  /** Mapping of class to apply for a given state
   * @type object
   * */
  this.statusClass = (options && options.statusClass) || {
    'success': 'success',
    'error': 'error',
    'pending': 'pending',
  };

  /** The text to put inside the button for a given status
   * @type object
   * */
  this.statusText = {
    error: this.el.getAttribute('data-error-handler'),
    success: this.el.getAttribute('data-success-handler'),
    default: this.el.innerHTML,
  };

  /** An error text to display before the button
   * @type text
   * */
  this.errorText = this.el.getAttribute('data-error-message');

  /**  An Element with a custom error message to display before button
   *  Will be initialized if error status is reached
   *  @type Element - The htmlElement to place before button
   * */
  this.errorMessage = undefined;

  /**  An Element with a custom success message to display before button
   *  Will be initialized if success status is reached
   *  @type Element - The htmlElement to place before button
   * */
  this.successMessage = undefined;

  /** List of string, specific error message to display, will not be populated here
   * @type Array
   * */
  this.errorMessages = [];

  /**  An Element to display errorMessages above the form
   *  @type Element - the wrapper of HTMLElementList
   * */
  this.errorList = undefined;

};

/**  Manage button statusCLass switch.
 *  will remove/add class regarding the given newStatus
 *  And also trigger specific method for certain status
 *  @param {String} newStatus - the new status to apply
 *  @return void
 * */
FormFeedback.prototype.setStatus = function (newStatus) {
  // if element already have "newStatus" class, quit
  if (this.status === newStatus) {
    return;
  }

  // update stored status
  this.status = newStatus;

  // toggle error display
  this.toggleErrorMessage(newStatus === 'error');
  this.toggleErrorList(newStatus === 'error');

  // toggle success display
  this.toggleSuccessMessage(newStatus === 'success');
};

/* Error message */

/**  Will create an HTMLElement for error message wrapping and assign it to this.errorMessage
 *  @return void
 * */
FormFeedback.prototype.createErrorMessage = function () {
  var errorMessage = document.createElement('span');
  errorMessage.classList.add('alert', 'alert--box', 'alert--error');
  errorMessage.innerHTML = this.errorText;
  this.errorMessage = this.el.parentNode.insertBefore(errorMessage, this.el);

};

/**  Show/hide error-message via utility class 'hidden' regarding passing param
 *  If this.errorMessage does not exist, it will create it before switching
 *  @param {boolean} show - whenever or not message should be visible
 *  @return void
 * */
FormFeedback.prototype.toggleErrorMessage = function (show) {
  if (!this.errorMessage) {
    this.createErrorMessage();

  }
  this.errorMessage.classList[show ? 'remove' : 'add']('hidden');

};

/* Error List */

/**  Will create an HTMLElement for error messages wrapping and assign it to this.errorList
 *  It create an `ul` element wrappend into a div with an hardcoded id 'contact-feedback'
 *  The hardcoded id could lead to some issue, it should be a class instead
 *  @return void
 * */
FormFeedback.prototype.createErrorList = function () {
  var errorList = document.createElement('div');
  errorList.setAttribute('id', 'contact-feedback');
  errorList.appendChild(document.createElement('ul'));
  this.errorList = this.el.parentNode.parentNode.appendChild(errorList);

};

/**  Show/hide error-list via utility class 'hidden' regarding passing param
 *  If this.errorList does not exist, it will create it before switching
 *  Populate list with message contains into this.errorMessages
 *  If this.errorMessages is empty it will not display the list
 *  @param {boolean} show - whenever or not list should be visible
 *  @return void
 * */
FormFeedback.prototype.toggleErrorList = function (show) {
  if (!this.errorList) {
    this.createErrorList();

  }
  if (show && this.errorMessages.length) {
    this.errorList.classList.remove('hidden');
    this.updateErrorList();

  } else {
    this.errorList.classList.add('hidden');
    this.updateErrorList(true);

  }
};

/**  Populate this.errorList with string contains in this.errorMessages, or clear the list
 * @param {boolean} clear - whenever or not we should remove all item for list
 * @return void
 * */
FormFeedback.prototype.updateErrorList = function (clear) {
  var list = this.errorList.childNodes[0]; // should be ul element
  if (clear) {
    list.childNodes.forEach(function (item, index) {
      item.remove();

    });
  } else {
    var listItem;
    this.errorMessages.forEach(function (item, index) {
      listItem = document.createElement('li');
      listItem.classList.add('alert', 'alert--error');
      listItem.innerHTML = item;
      list.appendChild(listItem);

    });
  }
};

/* Success message */

/**  Show/hide success-message via utility class 'hidden' regarding passing param
 *  If this.errorMessage does not exist, it will create it before switching
 *  @param {boolean} show - whenever or not message should be visible
 *  @return void
 * */
FormFeedback.prototype.toggleSuccessMessage = function (show) {
  if (!this.successMessage) {
    this.createSuccessMessage();

  }
  this.successMessage.classList[show ? 'remove' : 'add']('hidden');

};

/**  Will create an HTMLElement for success message wrapping and assign it to this.successMessage
 *  @return void
 * */
FormFeedback.prototype.createSuccessMessage = function () {
  var successMessage = document.createElement('span');
  successMessage.classList.add('alert', 'alert--success', 'alert--box');
  successMessage.innerHTML = this.statusText.success;
  this.successMessage = this.el.parentNode.insertBefore(successMessage, this.el);

};
